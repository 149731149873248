<template>
    <section class="ContentsPage" id="investors-section">
        <header>
            <section class="WrapperInfo">
                <drag-hint hide-on-mobile light/>
                <responsive-image
                    class="WrapperInfo-Cover"
                    :mobileSrc="top_img_mobile"
                    :desktopSrc="top_img_desktop"
                    background
                />
                <div class="WrapperInfo-Wrapper">
                    <flat-button
                        :to="{ name: 'about' }"
                        el="router-link"
                        class="WrapperInfo-BackButton"
                        outline
                    >
                        Volver
                    </flat-button>
                    <h2 class="WrapperInfo-SectionName">
                        <slot name="sectionName">Acerca de</slot>
                    </h2>
                    <h1 class="WrapperInfo-Title">
                        <slot name="title">{{ title }}</slot>
                    </h1>
                </div>
            </section>
        </header>
        <div class="investors_page" style="border: 1px solid rgb(222, 222, 222);width:100%;display:block;margin:5% auto; padding:0;max-width:1160px;">
            <div>
                <h4 style="font-family:HeadingCompressedPro,sans-serif;margin-top:1em; font-size: 2em; text-decoration:underline">Relación con Inversionistas</h4>
                <p>Abril Suarez Vazquez</p>
                <p>Correo: <a href="mailto:inversionistas@begrand.mx">inversionistas@begrand.mx</a></p>
                <p>Tel: +52 (55) 1100-1110</p>
            </div>
        </div>
        <h3 class="table-title" v-on:click="showTable">Últimos reportes <span class="icon">></span></h3>
        <div class="table-wrapper active">
            <table>
                <tbody>
                    <tr v-for="(_new, index) in news" v-bind:key="index">
                        <td class="over">
                            <a :href="_new.file" class="download-file" :title="_new.name" download target="_blank">
                                <span>{{ _new.name }} - {{ _new.year }}</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-container" v-for="(group, index) in filter(groups, 0, 3)" v-bind:key="index">
            <h3 class="table-title" v-on:click="showTable">{{ group[0].category_file_pages[0].name }} <span class="icon">></span></h3>
            <div class="table-wrapper" :id="index">
                <table>
                    <tbody>
                        <tr v-for="(files, index) in sort_by(group, 'year').slice().reverse()" v-bind:key="index">
                            <td>{{ files[0].year }}</td>
                            <td v-for="(f, index) in sort_by(files, 'group')" v-bind:class="[f.length == 1 ? 'over' : '']" v-bind:key="index">
                                <table v-if="f.length > 1 ">
                                    <tbody>
                                        <tr v-for="(file, index) in f" v-bind:key="index">
                                            <td class="over">
                                                <a :href="file.file" class="download-file" :title="file.name" target="_blank" download><span>{{ file.name }}</span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a v-else :href="f[0].file" class="download-file" :title="f[0].name" target="_blank" download><span>{{ f[0].name }}</span></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="table-container" id="half-tables-container">
            <table v-for="(group, index) in filter(groups, 5, 6)" v-bind:key="index">
                <thead>
                    <th>{{ group[0].category_file_pages[0].name }}</th>
                </thead>
                <tbody>
                    <tr v-for="(files, index) in sort_by(group, 'year').slice().reverse()" v-bind:key="index">
                        <td v-for="(f, index) in sort_by(files, 'group')" v-bind:class="[f.length == 1 ? 'over' : '']" v-bind:key="index">
                            <table v-if="f.length > 1 ">
                                <tbody>
                                    <tr v-for="(file, index) in f" v-bind:key="index">
                                        <td class="over">
                                            <a :href="file.file" class="download-file" :title="file.name" target="_blank" download><span>{{ file.name }}</span></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <a v-else :href="f[0].file" class="download-file" :title="f[0].name" target="_blank" download><span>{{ f[0].name }}</span></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-container" v-for="(group, index) in filter(groups, 4, 4)" v-bind:key="index">
            <h3 class="table-title" v-on:click="showTable">{{ group[0].category_file_pages[0].name }} <span class="icon">></span></h3>
            <div class="table-wrapper" :id="index">
                <table>
                    <tbody>
                        <tr v-for="(files, index) in sort_by(group, 'year').slice().reverse()" v-bind:key="index">
                            <td>{{ files[0].year }}</td>
                            <td v-for="(f, index) in sort_by(files, 'group')" v-bind:class="[f.length == 1 ? 'over' : '']" v-bind:key="index">
                                <table v-if="f.length > 1 ">
                                    <tbody>
                                        <tr v-for="(file, index) in f" v-bind:key="index">
                                            <td class="over">
                                                <a :href="file.file" class="download-file" :title="file.name" target="_blank" download><span>{{ file.name }}</span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a v-else :href="f[0].file" class="download-file" :title="f[0].name" target="_blank" download><span>{{ f[0].name }}</span></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
import ResponsiveImage from '@/app/components/ResponsiveImage.vue'
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import DragHint from '@/app/components/DragHint.vue'
import SectionPage from '@/app/mixins/SectionPage'
import MixHead from '@/app/mixins/MixHead'
import axios from 'axios'
import TweenMax from "gsap"
import { groupBy, orderBy } from "lodash"
import { environment } from '../../config/index'

export default {
    name: 'ContentsPage',
    components: {
        ResponsiveImage,
        DragHint,
        FlatButton
    },
    mixins: [
        SectionPage,
        MixHead,
    ],
    data () {
        return {
            title: "",
            data: null,
            news: [],
            top_img_desktop: "",
            top_img_mobile: "",
            groups: []
        }
    },
    mounted() {
        axios.get(`${environment.url.api}/page/by_perma_link?perma_link=/inversionistas`)
            .then((data) => {
                this.data = data.data.response.entry;
                this.title = this.data.title;
                this.top_img_desktop = data.data.response.entry.top_image_desktop;
                this.top_img_mobile = data.data.response.entry.top_image_mobile;
                this.news = this.data.file_news.slice(0, 2);
                this.groups = groupBy(this.data.file, "category_file_pages_id");
            })
    },
    methods: {
        showTable (e) {
            const container = e.target.nextSibling;
            const table = container.children[0];
            if (e.target.getAttribute("class").indexOf("active") < 0) {
                setTimeout(() => {
                    this.showDocs(container, table, e.target);
                }, 50);
            }
            TweenMax.set(".table-title.active", {
                className: "-=active"
            });
            TweenMax.to(".table-wrapper.active", 0.3, {
                ease: Power3.easeOut,
                className: "-=active",
                height: 0
            });
        },
        showDocs(container, table, target) {
            TweenMax.set(target, {
                className: "+=active"
            });
            TweenMax.to(container, 0.3, {
                ease: Power3.easeOut,
                className: "+=active",
                height: table.getBoundingClientRect().height + 2
            });
        },
        filter (data, a, b) {
            const group = [];
            Object.keys(data).forEach((element) => {
                if (data[element][0].category_file_pages_id >= a && data[element][0].category_file_pages_id <= b) group.push(data[element]);
            });
            return group;
        },
        sort_by (array, attr) {
            const grouped = groupBy(array, attr)
            return orderBy(grouped, ['year'])
        }
    },
    computed: {
        /*
        metas() {
            const { section } = this
            return section.meta_tags
        }
        */
    }
}
</script>
<style src="styles/views/ContentsPage.styl" lang="stylus"></style>
